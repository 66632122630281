<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <i  class="icon" :class="nav.icon"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <div class="container">
            <div class="nf-tabs-filter-form">
              <el-form class="filter-form" :inline="true" :model="filterForm" :rules="rulesForm" ref="filterForm">
                <el-form-item label="登记属性">
                  <el-select class="form-row" v-model="filterForm.registerProperty" placeholder="请选择">
                    <el-option :label="item.name" :value="item.value" v-for="(item,index) in registerPropList" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="南繁方向">
                  <el-select class="form-row" v-model="filterForm.nanfanDirection" placeholder="请选择">
                    <el-option label="科研育种" :value="1">科研育种</el-option>
                    <el-option label="种子生产" :value="2">种子生产</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="登记年份">
                  <el-date-picker v-model="filterForm.year" value-format="yyyy" type="year" placeholder="请选择"></el-date-picker>
                </el-form-item>
                <el-form-item label="名称">
                  <el-input class="form-row" v-model="filterForm.name" placeholder="请输入单位/企业/个人名称"></el-input>
                </el-form-item>
                <el-form-item label="单位级别">
                  <el-select class="form-row" v-model="filterForm.level" placeholder="请选择">
                    <el-option :label="item.name" :value="item.value" v-for="(item,index) in levelList" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="基地个数" prop="baseNumber">
                  <el-input class="form-row" v-model.number="filterForm.baseNumber" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="作物数量" prop="cropNumber">
                  <el-input class="form-row" v-model.number="filterForm.cropNumber" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="成果个数" prop="achievementNumber">
                  <el-input class="form-row" v-model.number="filterForm.achievementNumber" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="审核状态">
                  <el-select class="form-row" v-model="filterForm.registerStatus" placeholder="请选择">
                    <el-option label="审核中" :value="1">审核中</el-option>
                    <el-option label="审核通过" :value="2">审核通过</el-option>
                    <el-option label="已驳回" :value="3">已驳回</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submit">查询</el-button>
                  <el-button type="primary" @click="resetSubmit">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="nf-tabs-content">
              <el-divider></el-divider>
              <div class="nf-tabs-cont">
                   <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column prop="registerProperty" label="登记属性"></el-table-column>
                    <el-table-column prop="nanfanDirection" label="南繁方向"></el-table-column>
                    <el-table-column prop="year" label="登记年份"></el-table-column>
                    <el-table-column prop="name" width="200" align="center" label="名称(科研/高校/企业/个人)"></el-table-column>
                    <el-table-column prop="level" label="单位级别">
                      <template slot-scope="scope">
                        <span v-if="scope.row.level === 1">国家级</span>
                        <span v-else-if="scope.row.level === 2">省级</span>
                        <span v-else-if="scope.row.level === 3">地级市</span>
                        <span v-else>其他</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="baseNumber" label="基地个数"></el-table-column>
                    <el-table-column prop="cropNumber" label="作物数量"></el-table-column>
                    <el-table-column prop="achievementNumber" label="成果个数"></el-table-column>
                    <el-table-column prop="registerStatus" label="审核状态"></el-table-column>
                    <el-table-column label="操作" width="140" align="center">
                      <template slot-scope="scope">
                        <el-button size="mini" type="primary" v-if="scope.row.registerStatus === '审核中'" @click="detailsRow(scope.row, 2)" plain>审核</el-button>
                        <el-button size="mini" type="primary" v-if="scope.row.registerStatus !== '审核中'" :disabled="true" plain>审核</el-button>
                        <el-button size="mini" type="success" @click="detailsRow(scope.row, 1)" plain>查看详情</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                <!-- 分页 -->
                <div class="page-wrap">
                  <el-pagination
                    background
                    layout="total, prev, pager, next, jumper"
                    :total="total_page"
                    :page-size="page_size"
                    :current-page.sync="cur_page"
                    @current-change="pageChange"
                  ></el-pagination>
                </div>
              </div>
            </div>
            
            <!-- 审批 - 弹出框 -->
            <el-dialog :title="dialogTitle" :visible.sync="detailVisible" width="65%" top="3%" align="center">
              <modal-detail v-if="detailVisible" :rowData="detailData" :detailType="detailType" @close="detailVisible = false" @submit="handelSubmit" />
            </el-dialog>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
import modalDetail from './components/modal-detail';

export default {
  components: {
    nfBreadcrumb,
    modalDetail
  },

  data() {
    const checkNumber = (rule, value, callback) => {
      console.log(value);
      setTimeout(() => {
        if (!value) {
          return callback();
        }
        if (!Number.isInteger(value) || value < 0) {
          callback(new Error('请输入正整数'));
        } else {
          callback();
        }
      }, 10);
    };
    return {
      activeNav: 0,
      tabsNav: [ { name: "登记信息管理", icon: "icon-processing", url: "/nanfaninformatManage" },
        { name: "南繁备案统计", icon: "icon-draft", url: "/nanfanStatistics" } ],
      breadList: [ { name: "登记管理" } ],
      filterForm: {
        registerProperty: '', // 登记属性
        nanfanDirection: '', // 南繁方向: 1科研育种 2种子生产
        year: '', // 登记年份
        name: '', // 名称
        level: '', // 单位级别
        baseNumber: '', // 基地个数
        cropNumber: '', // 作物数量
        achievementNumber: '', // 成果个数
        registerStatus: '' // 审核状态
      },
      rulesForm: {
        baseNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ],
        cropNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ],
        achievementNumber: [
          { validator: checkNumber, trigger: 'blur' }
        ]
      },
      // 登记属性下拉list
      registerPropList: [
        {
          name: '科研单位',
          value: 1
        }, {
          name: '高等院校',
          value: 2
        }, {
          name: '企业',
          value: 3
        }, {
          name: '个人',
          value: 4
        }, {
          name: '其他',
          value: 5
        }
      ],
      // 单位级别 下拉数据
      levelList: [
        {
          name: '国家级',
          value: 1
        }, {
          name: '省级',
          value: 2
        }, {
          name: '地级市',
          value: 3
        }, {
          name: '其他',
          value: 4
        }
      ],
      page_size: 10,
      total_page: 0,  // 列表数据总页数
      cur_page: 1, // 当前页
      tableData: [],
      detailVisible: false, // 弹出框 是否显示
      dialogTitle: '', // 弹出框标题
      detailData: '', // 详情信息
      detailType: 0
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    // 获取列表数据
    async getListData(page) {
      const params = {
        page: page || this.cur_page,
        pageNum: page || this.cur_page,
        pageSize: this.page_size,
        achievementNumber: this.filterForm.achievementNumber,
        baseNumber: this.filterForm.baseNumber,
        cropNumber: this.filterForm.cropNumber,
        level: this.filterForm.level,
        name: this.filterForm.name,
        nanfanDirection: this.filterForm.nanfanDirection,
        registerProperty: this.filterForm.registerProperty,
        registerStatus: this.filterForm.registerStatus,
        year: this.filterForm.year
      };
      const data = await this.$fetchData.fetchPost(params, '/api/keeponrecord/backend/register/getBasisList', 'json');
      if (data.code === '200') {
        if (data.result && data.result.list) {
          this.tableData = data.result.list;
          this.total_page = data.result.total;
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    submit() {
      this.$refs.filterForm.validate(vali => {
        if (vali) {
          this.cur_page = 1;
          this.getListData();
        } else {
          return false;
        }
      });
    },
    /** 重置 */
    resetSubmit() {
      this.cur_page = 1;
      this.filterForm.registerProperty = '';
      this.filterForm.nanfanDirection = '';
      this.filterForm.year = '';
      this.filterForm.name = '';
      this.filterForm.level = '';
      this.filterForm.baseNumber = '';
      this.filterForm.cropNumber = '';
      this.filterForm.achievementNumber = '';
      this.filterForm.registerStatus = '';
      this.getListData();
    },
    /** 审批、详情 */
    detailsRow(data, type) {
      this.dialogTitle = type === 1 ? '备案详情' : '审核';
      this.detailVisible = true;
      this.detailData = data;
      this.detailType = type;
    },
    /** 提交 */
    async handelSubmit(data) {
      const result = await this.$fetchData.fetchPost(data, '/api/keeponrecord/backend/register/audit', 'json');
      if (result.code === '200') {
        this.$message.success('审核成功');
        this.detailVisible = false;
        this.getListData();
      } else {
        this.$message({ type: 'error', message: result.message });
      }
    },
    /** 改变页码 */
    pageChange(page) {
      this.getListData(page);
    },
    handleSizeChange(size) {
      this.page_size = size;
      this.getListData();
    }
  }
};
</script>
<style lang="less" scoped>
.el-form-item{
  margin-right: 30px;
}
.filter-form {
  padding-top: 10px;
  /deep/.el-input{
    width:110px;
  }
}
/deep/.el-button--mini, /deep/.el-button--mini.is-round {
    padding: 6px 4px;
}
.el-divider--horizontal {
  margin: 0px;
}
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }
    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
  }
  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);
    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: 700;
      color: #3380fe;
      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
.page-wrap{
  text-align: center;
  padding: 25px 0;
}
</style>
